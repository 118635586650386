import { Controller } from "@hotwired/stimulus"

export default class extends Controller { 
  connect() {     
    if(window.current === window.location.pathname) return;
    
    const googleId = document.querySelector("meta[name='google-analytics-id']")?.content;

    if(googleId) {
      gtag("event", "page_view", {
        send_to: googleId,
        page_path: window.location.pathname,
        page_title: document.title,
        page_location: window.location.href
      });
    }
    
    window.current = window.location.pathname;
  }
}