import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["cover", "overflow", "text"];

  connect() {
    this.checkOverflow();
  }

  checkOverflow() {
    const coverHeight = this.coverTarget.clientHeight;
    const textHeight = this.textTarget.clientHeight;

    if (textHeight > coverHeight) {
      this.textTarget.style.minHeight = `${coverHeight}px`;
      this.textTarget.style.maxHeight = `${coverHeight}px`;
      this.overflowTarget.classList.add("show");
      this.element.querySelector(".overflow button").addEventListener("click", (e) => {
        const isOpened = this.textTarget.classList.toggle("opened");
        e.target.textContent = isOpened ? "Lire moins" : "Lire plus";
      });
    }
  }
}
