import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["additional", "categoryList", "filter", "form", "input", "more", "sort"];

  connect() {
    this._focusAndPlaceCaretAtEnd();
    this._checkCategoryFilter();
    this._reFormatUrl();
  }

  search() {
    this._updateFilter();
    this._updateHiddenField();
    Rails.fire(this.formTarget, "submit", { propagate: true });
  }

  showMoreFilters(e) {
    if (e) e.preventDefault();
    this.moreTarget.classList.toggle("d-none");
    this.additionalTargets.forEach((target) => {
      target.classList.toggle("d-none");
    });
  }

  _updateFilter() {
    const urlParams = new URLSearchParams(window.location.search);
    const currentFilter = urlParams.get("filter");
    
    if (currentFilter) this.filterTarget.value = currentFilter;
  }

  _updateHiddenField() {
    if (!this.hasSortTarget) return;
    
    const sortValue = this.sortTarget.value;
    const hiddenField = this.formTarget.querySelector("input[name='sort_by']");
    hiddenField.value = sortValue;
  }

  _checkCategoryFilter() {
    const categories = this.categoryListTarget.value.split(',').map(category => category.toLowerCase());
  
    const urlParams = new URLSearchParams(window.location.search);
    const categoryFromURL = urlParams.get("category");
    
    if (categories.includes(categoryFromURL)) {      
      this.showMoreFilters();
    }
  }
  
  _focusAndPlaceCaretAtEnd() {
    const inputElem = this.inputTarget;
    inputElem.focus();
    
    if (typeof inputElem.selectionStart === "number") {
      inputElem.selectionStart = inputElem.selectionEnd = inputElem.value.length;
    } else if (typeof inputElem.createTextRange !== "undefined") {
      const range = inputElem.createTextRange();
      range.collapse(false);
      range.select();
    }
  }

  _reFormatUrl() {
    const currentURL = new URL(window.location.href);
    const decodedURL = decodeURIComponent(currentURL.toString());

    const updatedURL = decodedURL.replace(/Story/gi, "article").replace(/Podcast/gi, "podcast");

    if (currentURL.toString() !== updatedURL) {
      window.history.replaceState({}, '', updatedURL);
    }
  }
}
