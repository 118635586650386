document.addEventListener("turbo:visit", () => {
  var layout = document.querySelector(".layout-story");
  if(layout) {
    sessionStorage.setItem("turboVisited", "true");
  }
});

document.addEventListener("turbo:load", () => {
  var noAd = document.body.classList.contains("no-ad");
  if(noAd) return;

  var layout = document.querySelector(".layout-story");
  var site = document.body.classList.contains("korii")?"slatefr-korii":"slatefr-slatefr";

  var slots = [];
  if(document.querySelector("#taboola-right-rail-thumbnails")) {
    slots.push({
      mode: "thumbnails-rr",
      container: "taboola-right-rail-thumbnails",
      placement: "Right Rail Thumbnails"
    });
  }
  if(document.querySelector("#taboola-rec-below-article-thumbnails")) {
    slots.push({
      mode: "rec-reel-3n4-a",
      container: "taboola-rec-below-article-thumbnails",
      placement: "Rec Below Article Thumbnails"
    });
  }
  if(document.querySelector("#taboola-below-article-thumbnails")) {
    slots.push({
      mode: "alternating-thumbnails-a",
      container: "taboola-below-article-thumbnails",
      placement: "Below Article Thumbnails"
    });
  }
  if(document.querySelector("#taboola-below-article-thumbnails1")) {
    slots.push({
      mode: "alternating-thumbnails-a",
      container: "taboola-below-article-thumbnails1",
      placement: "Below Article Thumbnails1"
    });
  }

  if(layout) {
    var turboVisited = sessionStorage.getItem("turboVisited");
    window._taboola = window._taboola || [];
    if(turboVisited) {
      _taboola.push({notify:'newPageLoad'});
    } else {
      window.__tcfapi("addEventListener", 2, function(tcData, success) {
        if (success) {
          const listenerId = tcData.listenerId;
          const eventStatus = tcData.eventStatus;
          if (!eventStatus && tcData.gdprApplies) {
            return;
          }
          if (eventStatus === "tcloaded" || eventStatus === "useractioncomplete" || !tcData.gdprApplies || (eventStatus === "cmpuishown" && (tcData.tcString && tcData.vendor.consents[42] && ((tcData.purpose.consents[1] && !tcData.purposeOneTreatment) || (tcData.publisherCC === 'DE' && tcData.purposeOneTreatment))))) {
            window._taboola = window._taboola || [];
            !function (e, f, u, i) {
              if (!document.getElementById(i)){
                e.async = 1;
                e.src = u;
                e.id = i;
                f.parentNode.insertBefore(e, f);
              }
            }(document.createElement('script'),
            document.getElementsByTagName('script')[0],
            '//cdn.taboola.com/libtrc/'+site+'/loader.js',
            'tb_loader_script');
            if(window.performance && typeof window.performance.mark == 'function')
              {window.performance.mark('tbl_ic');}
            __tcfapi('removeEventListener', 2, () => {}, listenerId);
            return;
          }
        }
      });
    }
    _taboola.push({article:'auto'});
    for(let slot of slots) {
      _taboola.push({
        mode: slot.mode,
        container: slot.container,
        placement: slot.placement,
        target_type: 'mix'
      });
    }
    _taboola.push({flush: true});
  }
});