import { Controller } from "@hotwired/stimulus"
import Swiper from "swiper";
import { FreeMode, Navigation, Pagination } from "swiper/modules";

export default class extends Controller {
  connect() {
    const prevButton = this.element.querySelector('.prev-button');
    const nextButton = this.element.querySelector('.next-button');
    const pagination = this.element.querySelector('.swiper-pagination');
    const slides = this.element.querySelector('.swiper');

    const fullWidth = this.data.get("size") && this.data.get("size") == "full";
    const mobileEnabled = (!this.data.get("mobile")) || (this.data.get("mobile") && this.data.get("mobile") === "true");
    const tabletEnabled = (!this.data.get("tablet")) || (this.data.get("tablet") && this.data.get("tablet") === "true");
    const desktopEnabled = (!this.data.get("desktop")) || (this.data.get("desktop") && this.data.get("desktop") === "true");
    const freeMode = (this.data.get("free") && this.data.get("free") === "true") || false;

    const options = {
      modules: [FreeMode, Navigation],
      navigation: {},
      pagination: {},
      direction: "horizontal",
      slidesPerView: this.data.get("slides") || "auto",
      effect: "slider",
      speed: this.data.get("effect") || 300,
      loop: false,
      grabCursor: true,
      enabled: mobileEnabled,
      spaceBetween: this.data.get("space-mobile") ? parseInt(this.data.get("space-mobile")) : 15,
      slidesOffsetBefore: this.data.get("left-mobile") ? parseInt(this.data.get("left-mobile")) : 15,
      slidesOffsetAfter: this.data.get("right-mobile") ? parseInt(this.data.get("right-mobile")) : 15,
      freeMode: freeMode,
      breakpoints: {
        768: {
          enabled: tabletEnabled,
          spaceBetween: this.data.get("space-tablet") ? parseInt(this.data.get("space-tablet")) : 20,
          slidesOffsetBefore: this.data.get("left-tablet") ? parseInt(this.data.get("left-tablet")) : 30,
          slidesOffsetAfter: this.data.get("right-tablet") ? parseInt(this.data.get("right-tablet")) : 30
        },
        1024: {
          enabled: desktopEnabled,
          spaceBetween: fullWidth ? 0 : (this.data.get("space-desktop") ? parseInt(this.data.get("space-desktop")) : 30),
          slidesOffsetBefore: fullWidth ? 0 : (this.data.get("left-desktop") ? parseInt(this.data.get("left-desktop")) : 0),
          slidesOffsetAfter: fullWidth ? 0 : (this.data.get("right-desktop") ? parseInt(this.data.get("left-desktop")) : 40)
        }
      }
    };
    if(prevButton) {
      options["navigation"]["prevEl"] = prevButton;
    }
    if(nextButton) {
      options["navigation"]["nextEl"] = nextButton;
    }
    if(pagination) {
      options["modules"].push(Pagination);
      options["pagination"] = {
        el: pagination,
        type: this.data.get("pagination") || "progressbar"
      };
    }
    this.swiper = new Swiper(slides, options);
    if(this.swiper.isLocked) {
      if(prevButton) prevButton.setAttribute("disabled", "disabled");
      if(nextButton) nextButton.setAttribute("disabled", "disabled");
    }
  }

  disconnect() {
    this.swiper.destroy(true, true);
  }
}
