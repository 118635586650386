import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["mobileShareButton", "shareButton"];
  
  connect() {
    if(this.hasMobileShareButtonTarget) {
      this.mobileShareButtonTarget.addEventListener("click", (event) => {
        const href = (this.data.get("href"))?atob(this.data.get("href")):window.location.href;

        if(navigator.share) {
          navigator.share({
            url: href
          });
        }
      });
    }

    this.shareButtonTargets.forEach((shareButton) => {
      shareButton.addEventListener("click", (event) => {
        event.preventDefault();
        event.stopPropagation();

        let href = (this.data.get("href"))?atob(this.data.get("href")):atob(shareButton.getAttribute("data-share-href"));
        const service = shareButton.getAttribute("data-share-service");

        if(service) {
          if(service === "link") {
            navigator.clipboard.writeText(href);
          } else {
            href = encodeURIComponent(href);
            switch(service) {
              case "facebook":
                href = `https://www.facebook.com/share.php?u=${href}`;
                break;
              case "linkedin":
                href = `https://www.linkedin.com/cws/share?url=${href}`;
                break;
              case "messenger-desktop":
                href = `https://www.facebook.com/dialog/send?app_id=328225707698687&link=${href}&redirect_uri=https://www.slate.fr/`;
                break;
              case "messenger-mobile":
                href = `fb-messenger://share/?link=${href}&app_id=328225707698687`;
                break;
              case "twitter":
                href = `https://twitter.com/intent/tweet?url=${href}`;
                break;
              case "whatsapp":
                href = `whatsapp://send?text=${href}`;
                break;
            }
            window.open(href, "_blank");
          }
        } else {
          window.open(href, "_blank");
        }
        return false;
      });
    });
  }
}